
import React from 'react'

import { I18n } from '@front/volcanion'
import { Box, Typography, Grid } from '@mui/material'

import Search from './Search'
import { OrdersCount, TotalExpenses, UsersCount } from './Charts'

const DashboardComponent = ({ totalExpanse }) => (
  <Box>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box p={2} boxShadow={3}>
          <Search />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box p={1} boxShadow={3}>
          <Typography fontSize={18}>{I18n.t('dashboard.total_spent')}: {totalExpanse}{I18n.t('fiat.eur.sign')}</Typography>
          <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'center'}><TotalExpenses /></Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box p={1} boxShadow={3}>
          <Typography fontSize={18}> {I18n.t('dashboard.user_number')}:</Typography>
          <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'center'}><UsersCount /></Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={1} boxShadow={3} >
          <Typography fontSize={18}>{I18n.t('dashboard.order_number')}:</Typography>
          <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'center'}><OrdersCount /></Box>
        </Box>
      </Grid>
    </Grid>
  </Box >
)


export default DashboardComponent
