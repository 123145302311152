import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [languages] = hooks.useSetting('supported_languages')

  const mergedProps = {
    languages
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
