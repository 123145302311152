import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => (props) => {
  const { model, primary_key } = props || {}
  const [record_id] = hooks.useFormState('record_id')
  const [model_name] = hooks.useFormState('model_name')

  const mergedProps = {
    record_id,
    model_name,
    model,
    primary_key
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
