import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

const withContainer = Component => props => {
  const { group_id } = hooks.useParams()
  const navigate = hooks.useNavigate()
  const [group] = hooks.useModel('group', [group_id], { populate: ['drivers', 'licenses', 'vehicles'], single: true })

  const onChange = useCallback(newValue => {
    switch (newValue) {
      case 1: return navigate(`/adherent/${group_id}`)
      case 2: return navigate(`/adherent/${group_id}/license/`)
      case 3: return navigate(`/adherent/${group_id}/vehicle/`)
      case 4: return navigate(`/adherent/${group_id}/driver/`)
    }
  }, [navigate, group_id])

  const disabled = !group_id
  const licenseCount = (_.get(group, 'licenses') || []).length
  const vehicleCount = (_.get(group, 'vehicles') || []).length
  const driverCount = (_.get(group, 'drivers') || []).length

  const options = [{
    label: I18n.t('group.label', { count: 1 }),
    icon: 'account_balance',
    value: 1,
    disabled
  }, {
    label: I18n.t('contract.label', { count: licenseCount }),
    count: licenseCount,
    icon: 'article',
    value: 2,
    disabled
  }, {
    label: I18n.t('vehicle.label', { count: vehicleCount }),
    count: vehicleCount,
    icon: 'directions_car',
    value: 3,
    disabled
  }, {
    label: I18n.t('driver.label', { count: driverCount }),
    count: driverCount,
    icon: 'man',
    value: 4,
    disabled
  }]

  const mergedProps = {
    onChange,
    options,
    disabled
  }

  return <Component {...mergedProps} {...props} />
}
export default withContainer
