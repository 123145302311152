import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Grid, Box } from '@mui/material'
import { TextField } from '@front/squirtle'

const DEFAULT_TRANSLATIONS = [
  { name: 'name_trkey', labelKey: 'name.label.one', xs: 3, required: true },
  { name: 'description_trkey', labelKey: 'description.label.one', xs: 6, required: true }
]

const Row = ({ lang, index, translations = DEFAULT_TRANSLATIONS }) =>
  <Grid container item xs={12} spacing={1} alignItems='center'>
    <Grid item xs={1} hidden={lang !== 'fr'}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CDNSVG name='fr_flag' />
      </Box>
    </Grid>
    <Grid item xs={1} hidden={lang !== 'en'}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CDNSVG name='gb_flag' />
      </Box>
    </Grid>
    <Grid item xs={1} hidden={lang !== 'nl'}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box width='23.99px' height='17.99px'>
          <Box height={1 / 3} bgcolor='red' />
          <Box height={1 / 3} bgcolor='white' />
          <Box height={1 / 3} bgcolor='blue' />
        </Box>
      </Box>
    </Grid>
    {_.map(translations, (elem, key) => <Grid item xs key={key} rowSpacing={3}>
      <TextField
        name={`${elem.name}.${lang}`}
        label={index === 0 ? I18n.t(elem.labelKey) : null}
        emptyValue='-'
        multiline
        maxRows={2}
        required={elem.required}
        variant='standard'
        size='small' />
    </Grid>
    )}
  </Grid>

const Translation = ({ languages, ...rest }) => <Grid container rowSpacing={3}>
  {_.map(languages, (lang, index) =>
    <Row lang={lang} index={index} {...rest} key={index} />
  )}
</Grid>

export default Translation
