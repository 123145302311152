import React from "react"

import { hooks } from "@front/volcanion"

const withContainer = Component => props => {
  const [{ totalExpanse }] = hooks.useFormState()

  const mergedProps = {
    totalExpanse
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
