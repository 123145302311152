import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => (props) => {
  const { row, model, primary_key } = props || {}
  const primary_id = _.get(row, primary_key)
  const [deleteRecord] = hooks.useModelFunction(model, 'delete')
  const onDelete = useCallback(() => deleteRecord({ [primary_key]: primary_id })
    , [primary_id])

  const mergedProps = {
    row,
    onDelete
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
