import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid, Link } from '@mui/material'
import { TextComponent, Loader, DateTimeCompactComponent } from '@front/squirtle'

import HookUtils from '@front/volcanion/utils/hooks'
import { DriverLink } from '@abra/elements'

const CustomLink = ({ label, href, value, ...props }) =>
  <Grid item xs {...props}>
    <Typography sx={{ fontSize: 13 }}> {label} </Typography>
    <Typography>
      {!!value ?
        <Link href={href} target={'_blank'}>
          {value}
        </Link>
        : '-'
      }
    </Typography>
  </Grid>

const Column1 = ({ incident, driver_id, vehicle_id, vehicle_status, vehicle_info, license_id, license_client_id, license_group_id, vehicle_group_id, comment_reason }) =>
  <Grid container item xs sx={{ borderLeft: 1.5, borderColorLeft: 'primary.main', borderRight: 1.5, borderColorRight: 'primary.main' }}>
    <Grid item xs={12}>
      <Typography color={'primary'} variant={'h6'}> {I18n.t('driver.label', { count: 1 })} </Typography>
    </Grid>
    <Grid item xs={12}>
      <Loader isLoading={HookUtils.getPopulateLoading(incident, ['driver', 'vehicle', 'transport'])}>
        <Grid container>
          <Grid item xs={6}>
            <DriverLink title={I18n.t('driver.code')} record_id={driver_id} />
          </Grid>
          <Grid item xs={6}>
            <TextComponent label={I18n.t('driver.status')} value={vehicle_status} disabled />
          </Grid>
          <CustomLink
            xs={6}
            label={I18n.t('contract.id')}
            href={`/adherent/${license_group_id}/license/${license_id}`}
            value={license_client_id}
          />
          <CustomLink
            xs={6}
            label={I18n.t('vehicle.label', { count: 1 })}
            href={`/adherent/${vehicle_group_id}/vehicle/${vehicle_id}`}
            value={vehicle_info}
          />
        </Grid>
        {!!comment_reason &&
          <Grid item xs>
            <TextComponent label={I18n.t('incident.comment_reason')} value={comment_reason} disabled />
          </Grid>}
      </Loader>
    </Grid>
  </Grid>

const Column2 = ({ incident, user_id, user_name, company_id, company_name, formula_id, formula_name }) =>
  <Grid container item xs sx={{ borderRight: 1.5, borderColorRight: 'primary.main' }}>
    <Grid item xs={12}>
      <Typography color={'primary'} variant={'h6'}> {I18n.t('user.label', { count: 1 })} </Typography>
    </Grid>
    <Grid item xs={12}>
      <Loader isLoading={HookUtils.getPopulateLoading(incident, ['order.do'])}>
        <Grid container spacing={1}>
          <CustomLink
            xs={12}
            hidden={!user_id}
            label={I18n.t('user.label', { count: 1 })}
            href={`/client/${user_id}`}
            value={user_name}
          />
          <CustomLink
            xs={12}
            hidden={!company_id}
            label={I18n.t('company.label', { count: 1 })}
            href={`/company/${company_id}`}
            value={company_name}
          />
          <CustomLink
            hidden={!formula_name}
            label={I18n.t('commercial_formula.label', { count: 1 })}
            href={`/commercialformula/${formula_id}`}
            value={formula_name}
          />
        </Grid>
      </Loader>
    </Grid>
  </Grid>

const Column3 = ({ incident, schedule_type, requestedAt, source, order_status, order_status_color, transport_status, transport_status_color }) =>
  <Grid container item xs color={'primary'} variant={'h6'}>
    <Grid item xs={12}>
      <Typography color={'primary'} variant={'h6'}>{I18n.t('order.label', { count: 1 })} </Typography>
    </Grid>
    <Grid item xs={12}>
      <Loader isLoading={HookUtils.getPopulateLoading(incident, ['order', 'transport'])}>
        <Grid container>
          <Grid item xs={12}>
            <TextComponent label={I18n.t('address.start')} value={source} disabled />
          </Grid>
          <Grid item xs={6}>
            <DateTimeCompactComponent
              label={I18n.t('date.label', { count: 1 })}
              inputFormat={'DD/MM/YYYY HH:mm'}
              value={requestedAt}
              disableOpenPicker
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextComponent label={I18n.t('order.type', { count: 1 })} value={!!schedule_type ? I18n.t(`order.${schedule_type}.label`, { count: 1 }) : '-'} disabled />
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: 'text.label', fontSize: 12, fontWeigth: 600 }} > {I18n.t('order.status.label')}</Typography>
            <Typography color={order_status_color} fontWeight={600}>
              {order_status}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: 'text.label', fontSize: 12, fontWeigth: 600 }} > {I18n.t('transportStatus.label', { count: -1 })}</Typography>
            <Typography color={transport_status_color} fontWeight={600}>
              {transport_status}
            </Typography>
          </Grid>
        </Grid>
      </Loader>
    </Grid>
  </Grid>

const DefaultDetails = props =>
  <Box sx={{ br: 1, boxShadow: 6, p: 2 }}>
    <Grid container spacing={1} >
      <Column1 {...props} />
      <Column2 {...props} />
      <Column3 {...props} />
    </Grid>
  </Box>

export default React.memo(DefaultDetails)
