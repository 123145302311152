import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'

import { DriverLink, LicenseLink, VehicleLink } from '@abra/elements'

const Column1 = ({ driver_id }) =>
  <Grid container item xs sx={{ borderLeft: 2, borderColorLeft: 'primary.main', borderRight: 2, borderColorRight: 'primary.main' }}>
    <Grid item xs={12}>
      <Typography color={'primary'} variant={'h6'}> {I18n.t('driver.label', { count: 1 })} </Typography>
    </Grid>
    <Grid item xs>
      <DriverLink title={I18n.t('driver.code')} record_id={driver_id} />
    </Grid>
  </Grid>

const Column2 = ({ license_id, vehicle_id }) =>
  <Grid container item xs sx={{ borderRight: 2, borderColorRight: 'primary.main' }}>
    <Grid item xs={12}>
      <Typography color={'primary'} variant={'h6'}> {I18n.t('driver.pairing')} </Typography>
    </Grid>
    <Grid item xs>
      <LicenseLink title={I18n.t('contract.id')} record_id={license_id} />
    </Grid>
    <Grid item xs={12}>
      <VehicleLink title={I18n.t('vehicle.label', { count: 1 })} record_id={vehicle_id} />
    </Grid>
  </Grid>

const Column3 = ({ vehicle_driver_id, license_driver_id }) =>
  <Grid container item xs sx={{ borderRight: 2, borderColorRight: 'primary.main' }}>
    <Grid item xs={12}>
      <Typography color={'primary'} variant={'h6'}>  {I18n.t('driver.already_paired')} </Typography>
    </Grid>
    <Grid item xs>
      <DriverLink title={I18n.t('contract.already_taken')} record_id={license_driver_id} />
    </Grid>
    <Grid item xs={12}>
      <DriverLink title={I18n.t('vehicle.already_taken')} record_id={vehicle_driver_id} />
    </Grid>
  </Grid>

const PairingDetails = ({ driver_id, license_id, vehicle_id, vehicle_driver_id, license_driver_id }) =>
  <Box sx={{ br: 1, boxShadow: 6, p: 2 }}>
    <Grid container spacing={1}>
      <Column1 driver_id={driver_id} />
      <Column2 license_id={license_id} vehicle_id={vehicle_id} />
      <Column3 vehicle_driver_id={vehicle_driver_id} license_driver_id={license_driver_id} />
    </Grid>
  </Box>

export default React.memo(PairingDetails)
