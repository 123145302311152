
import React from 'react'

import { Typography, Box, Icon } from '@mui/material'
import Tabs, { TabHeader } from '@front/squirtle/components/Tabs/component'

const CustomHeader = ({ option, selectorProps, mode, ...props }) => {
  const isSelected = selectorProps?.isOptionSelected(option)
  const selectedColor = mode === 'light' ? 'primary.light' : 'primary.main'
  const defaultColor = mode === 'light' ? 'primary.dark' : 'white'
  const color = isSelected ? selectedColor : defaultColor
  return <>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon sx={{ fontSize: 27, mr: 1, color }}>{option?.icon}</Icon>
      <Typography variant={'h5'} sx={{ color }}>
        {option?.label} {!!option?.count && `- ${option?.count}`}
      </Typography>
    </Box>
  </>
}

const TabComponent = ({ onChange, value, options, mode, ...props }) =>
  <Tabs onChange={onChange} value={value} options={options} centered {...props}>
    <TabHeader><CustomHeader mode={mode} /></TabHeader>
  </Tabs >

export default React.memo(TabComponent)