import React, { useRef, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import moment from 'moment'

import {
  Chart,
  DoughnutController,
  ArcElement,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  PieController,
  LinearScale,
  CategoryScale,
  Filler,
  TimeScale
} from 'chart.js'

import 'chartjs-adapter-moment'

Chart.register(Tooltip, Legend)
Chart.register(DoughnutController, ArcElement, PieController)
Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler, TimeScale)
Chart.register(BarController, BarElement, CategoryScale, LinearScale)

const ChartComponent = ({ watchers = [], style = {}, config }) => {
  const chartRef = useRef()
  const chartInstance = useRef()
  const locale = hooks.useLocale()

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  useEffect(() => {
    const ctx = chartRef?.current?.getContext('2d')

    if (ctx) {
      !!chartInstance?.current && chartInstance.current.destroy()

      chartInstance.current = new Chart(ctx, config)
    }

    return () =>
      !!chartInstance?.current && chartInstance.current.destroy()
  }, [])

  useEffect(() => {
    !!chartInstance?.current && chartInstance.current.update()
  }, watchers)


  return (
    <canvas ref={chartRef} style={style} />
  )
}

export default ChartComponent
export {
  Chart as ChartJS
}
