class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler() {
    return function filterToState(state) {
      return {

      }
    }
  }
  static formToFilterHandler(model_name, record_id) {
    return function formToFilter(values, extra, meta, state) {
      return {
        [model_name]: record_id,
        ...values
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
        priority: 0
      }
    }
  }

  static formToRecordHandler(model_name, record_id) {
    return function formToRecord(values, extra, meta) {
      return {
        [model_name]: record_id,
        priority: values?.priority,
        orderlimit: {
          limit_type: values?.orderlimit?.limit_type,
          limit_amount: values?.orderlimit?.limit_amount,
          limit_count: values?.orderlimit?.limit_count,
          payment_type: values?.orderlimit?.payment_type,
          period: values?.orderlimit?.period
        }
      }
    }
  }
}

export default Callbacks
