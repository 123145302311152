import React from 'react'
import { I18n } from '@front/volcanion'
import { Box } from '@mui/material'

import Chart from '../../Chart'

const TotalExpensesChart = ({ datasets, labels, total, schedule_type }) => {

  const centerTextPlugin = {
    id: 'centerTextPlugin',
    beforeDraw: (chart) => {
      const { ctx } = chart
      ctx.save()

      const meta = chart.getDatasetMeta(0)
      const centerX = meta.data[0].x
      const centerY = meta.data[0].y

      const fontSize = _.max([12, _.min([chart.chartArea.width / 10, 22])])
      const fontFamily = 'Arial'

      ctx.font = `${fontSize}px ${fontFamily}`
      ctx.fillStyle = 'black'
      ctx.textBaseline = 'middle'

      const text1 = I18n.t('dashboard.order_total')
      const text2 = chart.options.plugins.centerText.text

      const text1Width = ctx.measureText(text1).width
      const text2Width = ctx.measureText(text2).width
      const text1X = centerX - (text1Width / 2)
      const text2X = centerX - (text2Width / 2)

      const lineHeight = fontSize * 1.4
      const text1Y = centerY - lineHeight / 2
      const text2Y = centerY + lineHeight / 2

      ctx.fillText(text1, text1X, text1Y)
      ctx.fillText(text2, text2X, text2Y)
      ctx.restore()
    }
  }

  const options = {
    maintainAspectRatio: false,
    cutout: !schedule_type ? '70%' : '85%',
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 70
      }
    },
    plugins: {
      centerText: {
        text: total
      },
      legend: {
        position: 'right',
        labels: {
          padding: 15,
          boxWidth: 15,
          font: { size: 16 },
          generateLabels: chart => {
            if (!chart?.data?.labels || !chart?.data?.datasets) return []
            const labels_chart = chart.data.labels
            const datasets_chart = chart.data.datasets

            return _.flatten(_.map(labels_chart, (labelGroup, datasetIndex) =>
              _.map(labelGroup, (text, index) => ({
                text,
                fillStyle: datasets_chart[datasetIndex].backgroundColor[index],
                value: datasets_chart[datasetIndex].data[index],
                hidden: false
              }))
            ))
          }
        }
      },
      tooltip: {
        callbacks: {
          boxPadding: 3,
          bodyFont: { size: 16 },
          title: () => '',
          label: (context) => {
            return _.join([
              `${context.chart.data.labels[context.datasetIndex][context.dataIndex]}:`,
              context.chart.data.datasets[context.datasetIndex].data[context.dataIndex]
            ], ' ')
          }
        }
      }
    }
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '280px' }}>
      <Chart
        config={{
          type: 'pie',
          data: { labels, datasets },
          options: options,
          plugins: [centerTextPlugin]
        }}
        style={{ width: '100%', height: '100%' }}
        watchers={[datasets]}
      />
    </Box>
  )
}

export default TotalExpensesChart
