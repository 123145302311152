import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [mode] = hooks.useGlobalState('mode')

  const mergedProps = {
    mode,
    value: 1
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
