import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

const withContainer = Component => ({ value, ...props }) => {
  const { company_id } = hooks.useParams()
  const navigate = hooks.useNavigate()
  const [company] = hooks.useModel('company', [company_id], { populate: ['contracts', 'companyusers'], single: true })

  const onChange = useCallback(newValue => {
    switch (newValue) {
      case 1: return navigate(`/company/${company_id}`)
      case 2: return navigate(`/company/${company_id}/contract/`)
      case 3: return navigate(`/company/${company_id}/user/`)
    }
  }, [navigate, company_id])

  const disabled = !company_id

  const contractCount = (_.get(company, 'contracts') || []).length
  const userCount = (_.get(company, 'companyusers') || []).length
  const options = [{
    label: I18n.t('company.label', { count: 1 }),
    icon: 'account_balance',
    value: 1,
    disabled
  }, {
    label: I18n.t('contract.label', { count: contractCount }),
    count: contractCount,
    icon: 'gavel',
    value: 2,
    disabled
  }, {
    label: I18n.t('user.label', { count: userCount }),
    count: userCount,
    icon: 'group',
    value: 3,
    disabled
  }]
  const mergedProps = {
    options,
    onChange,
    value
  }
  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
