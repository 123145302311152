import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import HookUtils from '@front/volcanion/utils/hooks'

const withContainer = Component => ({ event_id }) => {
  const [incident, event_state] = hooks.useModel('event', [event_id], {
    populate: [
      'driver',
      'vehicle',
    ],
    single: true
  })
  const related_license_id = _.get(_.find(_.get(incident, 'data'), ['key', 'license']), 'value')
  const [license, license_state] = hooks.useModel('license', [related_license_id], { single: true, populate: ['usual_vehicle'] })

  const mergedProps = {
    license_id: license?.license_id,
    driver_id: incident?.driver?.user_id,
    vehicle_id: incident?.vehicle?.vehicle_id,
    license_driver_id: license?.usual_vehicle?.driver,
    vehicle_driver_id: incident?.vehicle?.driver,
  }

  return (
    <Loader isLoading={!HookUtils.getReadyState([event_state, license_state])}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
