
import React from 'react'

import { I18n } from '@front/volcanion'

import { Box, Typography } from '@mui/material'

const NoResultComponent = props => {
  return (
    <Box>
      <Typography sx={{ opacity: 1 }} variant={'h5'} textAlign={'center'} letterSpacing={3} >{I18n.t('no_result')}</Typography>
    </Box >
  )
}

export default NoResultComponent
