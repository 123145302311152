import React from 'react'

import { hooks } from '@front/volcanion'

import moment from 'moment'

import Chart from '../../Chart'

const UsersCountChart = ({ data, date_start, date_end }) => {
  const colors = hooks.useRelayConfig('COLORS')

  const cumulativeData = _.reduce(data, (acc, curr, index) => {
    const prevValue = index > 0 ? acc[index - 1].y : 0
    acc.push({ x: curr.x, y: prevValue + curr.y })
    return acc
  }, [])

  const datasets = [{
    data: cumulativeData,
    borderColor: colors?.primary?.[700],
    borderWidth: 2,
    fill: true,
    tension: 0.8,
    borderDash: [3, 5],
    pointRadius: 0,
    cumulative: true,
    backgroundColor: function (context) {
      const chart = context.chart
      const { ctx, chartArea } = chart

      if (!chartArea) return null

      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
      const baseColor = '35,35,35'
      gradient.addColorStop(0, `rgba(${baseColor}, 0)`)
      gradient.addColorStop(0.25, `rgba(${baseColor}, 0.1)`)
      gradient.addColorStop(0.5, `rgba(${baseColor}, 0.2)`)
      gradient.addColorStop(0.75, `rgba(${baseColor}, 0.4)`)
      gradient.addColorStop(1, `rgba(${baseColor}, 0.6)`)

      return gradient
    }
  }]

  const maxValue = _.maxBy(cumulativeData, 'y')?.y
  const minValue = _.minBy(cumulativeData, 'y')?.y
  const valueRange = maxValue - minValue
  const stepSize = _.ceil(valueRange / 5)

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: { unit: 'year' },
        grid: { display: false },
        min: moment(date_start).format('YYYY-MM-DD'),
        max: moment(date_end).format('YYYY-MM-DD')
      },
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          stepSize: stepSize,
        },
      },
    },
    plugins: {
      legend: { display: false }
    }
  }

  return (
    <Chart
      config={{
        type: 'line',
        data: { datasets: datasets },
        options
      }}
      style={{ width: '100%', height: '280px' }}
      watchers={[data]}
    />
  )
}

export default UsersCountChart
