import React, { useCallback } from "react"

import { hooks } from '@front/volcanion'

import Callbacks from "./callbacks"

const withContainer = Component => props => {
  const setFormChange = hooks.useFormChange()
  const [company] = hooks.useFormState('company')
  const [date_start, date_end, date_type, contracts] = hooks.useFieldValues(['date_start', 'date_end', 'date_type', 'contracts'])

  const afterDateStartChange = useCallback(Callbacks.afterDateChangeHandler(setFormChange, date_end, 'start'), [setFormChange, date_end])
  const afterDateEndChange = useCallback(Callbacks.afterDateChangeHandler(setFormChange, date_start, 'end'), [setFormChange, date_start])
  const afterPeriodChange = useCallback(Callbacks.afterPeriodChangeHandler(setFormChange), [setFormChange])

  const mergedProps = {
    company,
    contracts,
    date_type,
    afterDateStartChange,
    afterDateEndChange,
    afterPeriodChange
  }

  return (
    <Component{...mergedProps} />
  )
}

export default withContainer
