import React from 'react'
import { hooks, I18n } from '@front/volcanion'

import moment from 'moment'

import Chart from '../../Chart'

const OrdersCountChart = ({ data, date_end, date_start }) => {
  const colors = hooks.useRelayConfig('COLORS')

  const datasets = [{
    label: I18n.t('dashboard.order.complete'),
    data: _.filter(data, ['status', 'complete']),
    backgroundColor: colors?.primary?.[600],
    borderColor: colors?.primary?.[700],
  }, {
    label: I18n.t('dashboard.order.cancel'),
    data: _.filter(data, ['status', 'canceled']),
    backgroundColor: colors?.error?.[600],
    borderColor: colors?.error?.[700],
  }, {
    label: I18n.t('dashboard.order.not_loaded'),

    data: _.filter(data, ['reason', 'not_served']),
    backgroundColor: colors?.gray?.[600],
    borderColor: colors?.gray?.[700],
  }]

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          font: { size: 16 },
          left: -40
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: { unit: 'year' },
        min: moment(date_start).format('YYYY-MM-DD'),
        max: moment(date_end).format('YYYY-MM-DD'),
        stacked: true,
        ticks: { autoSkip: false },
        grid: { display: false }
      },
      y: {
        stacked: true
      },
    }
  }

  return (
    <Chart
      config={{
        type: 'bar',
        data: { datasets },
        options
      }}
      style={{ width: '100%', height: '280px' }}
      watchers={[data]}
    />
  )
}

export default OrdersCountChart
