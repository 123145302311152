import React, { useCallback } from 'react'
import { I18n, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'
import { Box, StepContent, StepLabel, Typography } from '@mui/material'

const withForm = Component => ({ company_id }) => {
  const formProps = {
    name: 'dashboard_form',
    model_name: 'order',
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    getEmptyState: useCallback(Callbacks.getEmptyStateHandler(company_id), [company_id]),
    initialState: { isReadOnly: false },
    disableParentSubmitListener: true,
    disableParentDisabledListener: true
  }
  const mergedProps = {
  }
  return (
    <Box>
      <StepLabel>
        <Typography variant='h5' >{I18n.t('dashboard.label', { count: 1 })}</Typography>
      </StepLabel >
      <StepContent>
        <ModelForm {...formProps}>
          <Component {...mergedProps} />
        </ModelForm>
      </StepContent>
    </Box >
  )
}

export default withForm
