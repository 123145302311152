import React, { useEffect } from "react"

import { hooks } from "@front/volcanion"
import { HookUtils } from "@front/volcanion/utils"
import { Loader } from "@front/squirtle"

import NoResultComponent from "../noResult"

const withContainer = Component => props => {
  const [{ company }] = hooks.useFormState()
  const [contract, service, user, date_start, date_end] = hooks.useFieldValues(['contract', 'service', 'user', 'date_start', 'date_end'])

  const [data, search, state] = hooks.useModelValues('companyuser', 'get', {})

  const filter = {
    createdAt: { '>=': date_start, '<=': date_end },
    company,
    services: !!service && [service] || !!contract && { contract: { companycontract_id: contract } } || undefined
  }

  const options = {
    group_by: ['DATE(createdAt) AS x'],
    no_select: true,
    query_operations: [{ path: 'companyuser_id', operation: 'COUNT', alias: 'y' }],
    sort: ['DATE(createdAt) ASC'],
    select: [],
    log_query: true,
    limit: -1,
    type: 'b2b'
  }

  useEffect(() => {
    search({ filter, options })
  }, [contract, service, user, date_start, date_end])

  const mergedProps = {
    data: data,
    date_start: date_start,
    date_end: date_end
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([state])} size={100} >
      {_.isEmpty(data) ? <NoResultComponent /> : <Component {...mergedProps} />}
    </Loader>
  )
}

export default withContainer
