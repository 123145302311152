import moment from 'moment'

class Callbacks {
  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        date_start: moment().subtract(3, 'months').startOf('day').format(),
        date_end: moment().endOf('day').format()
      }
    }
  }
  static getEmptyStateHandler(company_id) {
    return function getEmptyState() {
      return {
        company: company_id
      }
    }
  }
}

export default Callbacks
