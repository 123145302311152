
import React from 'react'

import { I18n } from '@front/volcanion'
import { UserUtils } from '@front/volcanion/utils'
import { MenuSelectorField, ModelMenuSelectorField, DateTimeField } from '@front/squirtle'

import moment from 'moment'
import { Box, Grid } from '@mui/material'

const SearchComponent = ({
  company,
  date_type,
  contracts,
  afterDateStartChange,
  afterDateEndChange,
  afterPeriodChange
}) => (
  <Box>
    <Box>
      <Grid container spacing={3}>
        <Grid item xs>
          <ModelMenuSelectorField
            name='contract'
            model_name='companycontract'
            label={I18n.t('contract.label', { count: -1 })}
            labelKeys={['client_id']}
            searchMode={'search'}
            allowNone
            noneLabel={I18n.t('all')}
            noneFirst
            selectFirst
            config={{
              initial_filter: {},
              forced_filter: { company },
              watchers: [company]
            }}
          />
        </Grid>
        <Grid item xs>
          <ModelMenuSelectorField
            name='service'
            model_name='companyservice'
            label={I18n.t('companyservice.label', { count: -1 })}
            labelKeys={['client_id']}
            allowNone
            noneLabel={I18n.t('all')}
            noneFirst
            selectFirst
            searchMode={'search'}
            config={{
              initial_filter: {},
              forced_filter: { contract: contracts || { company } },
              watchers: _.flatten([company, contracts])
            }}
          />
        </Grid>
        <Grid item xs>
          <ModelMenuSelectorField
            name='user'
            model_name='companyuser'
            label={I18n.t('companyuser.label', { count: -1 })}
            allowNone
            noneLabel={I18n.t('all')}
            noneFirst
            selectFirst
            searchMode={'search'}
            config={{
              populate: ['user.customerinfo', 'user.info', 'contact'],
              initial_filter: {},
              forced_filter: { company },
              watchers: [company],
              mutator: record => ({
                label: UserUtils.getFormatedName({ ...record?.contact, designation: record?.user?.customerinfo?.name }),
                ...record,
                value: record?.user?.user_id
              })
            }}
          />
        </Grid>
        <Grid item xs>
          <MenuSelectorField
            name='schedule_type'
            label={I18n.t('dashboard.schedule_type')}
            allowNone
            noneLabel={I18n.t('all')}
            noneFirst
            selectFirst
            searchMode={'search'}
            options={[
              { label: I18n.t('order.planned.label', { count: -1 }), value: 'planned' },
              { label: I18n.t('order.immediate.label', { count: -1 }), value: 'immediate' }
            ]}
          />
        </Grid>
        <Grid item xs>
          <MenuSelectorField
            name='date_type'
            label={I18n.t('dashboard.at')}
            required
            options={[
              { label: I18n.t('dashboard.date.month.current'), value: 'current_month' },
              { label: I18n.t('dashboard.date.month.last'), value: 'last_month' },
              { label: I18n.t('dashboard.date.day.last', { count: 30 }), value: 'last_thirty_days' },
              { label: I18n.t('dashboard.date.day.last', { count: 90 }), value: 'last_ninety_days' },
              { label: I18n.t('dashboard.date.year.current', { count: 30 }), value: 'start_of_year' },
              { label: I18n.t('dashboard.date.year.specific', { count: moment().year() - 1 }), value: 'last_year' },
              { label: I18n.t('dashboard.custom'), value: 'custom' }
            ]}
            afterChange={afterPeriodChange}
          />
        </Grid>
      </Grid>
    </Box>
    {date_type === 'custom' &&
      <Box mt={1}>
        <Grid container spacing={3} justifyContent={'center'}>
          <Grid item xs={3}>
            <DateTimeField
              name='date_start'
              label={_.upperFirst(I18n.t('dashboard.at'))}
              compact
              afterChange={afterDateStartChange}
            />
          </Grid>

          <Grid item xs={3}>
            <DateTimeField
              name='date_end'
              label={I18n.t('dashboard.to')}
              compact
              afterChange={afterDateEndChange}
            />
          </Grid>
        </Grid>
      </Box>
    }
  </Box>
)

export default SearchComponent
