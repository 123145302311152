import _ from 'lodash'

function getTranslations() {
  return {
    fr: () => _.merge({},
      { voc: require("@front/volcanion/translations/fr").default },
      { sqr: require("@front/squirtle/translations/fr").default },
      require("./fr.json")
    ),
    en: () => _.merge({},
      { voc: require("@front/volcanion/translations/fr").default },
      { sqr: require("@front/squirtle/translations/fr").default },
      require("./fr.json")
    )
  }
}
export default getTranslations
